<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>优制云MES</h2>
          <div class="tit">
            实现高效的生产和精准的执行，降低生产成本，提高产品质量和市场竞争力。
          </div>
          <div class="tit">
            工厂全面数字化，实现生产过程的智能化和自动化，提高生产效率和质量，降低人工成本。
          </div>
          <div class="exp">立即体验</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <h4>优制云MES</h4>
          <div class="tit">工厂全面数字化，实现生产过程的智能化和自动化，</div>
          <div class="tit">提高生产效率和质量，降低人工成本。</div>
          <span class="exp">立即体验</span>
        </div>
      </div>

      <div class="customeValue">
        <h4 class="pro-tit">客户价值</h4>
        <h6 class="pro-tits">
          提高生产效率和质量，优化资源配置，降低成本，帮助企业更好地实现数字化和智能化制造的目标。
        </h6>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/product.png" alt="" />
                <h6>生产现场跟踪</h6>
                <p>
                  管理生产过程中的各种数据，实时了解订单完成情况、工序的进度、人员的分配等
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/plan.png" alt="" />
                <h6>生产计划协调</h6>
                <p>
                  MES系统结合了ERP系统，可以实现生产规划和实际生产的相互协调
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/store.png" alt="" />
                <h6>仓库管理</h6>
                <p>
                  帮助管理仓库，实现实物的实时统一，以及仓库的存放地点和管理
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/equ.png" alt="" />
                <h6>设备管理</h6>
                <p>
                  通过系统共享和传输的方式进行数据传输，减少了人员记录和复制的工作量，实现了对设备运行的实时监控
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/protect.png" alt="" />
                <h6>质量管理</h6>
                <p>
                  管理生产过程中的各种数据，实时了解订单完成情况、工序的进度、人员的分配等
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/file.png" alt="" />
                <h6>文档管理</h6>
                <p>
                  通过与PLM的互操作，MES系统可以自动获取和推送所需的图纸、通知、工艺反馈文件和设备操作指令等
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/kpi.png" alt="" />
                <h6>绩效管理</h6>
                <p>
                  对日常经营管理、生产工作量、技能素质等多方面问题进行考评，减少中间人为主要因素影响
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/product.png" alt="" />
                <h6>集成其他系统</h6>
                <p>
                  MES系统可与其他系统进行集成，如ERP、SCM、CRM等，实现数据的共享和流程的优化
                </p>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="sys container">
        <h4 class="pro-tit">系统架构</h4>
        <img
          src="@/assets/newSite/product/sys.png"
          style="width: 100%"
          alt=""
        />
      </div>

      <div class="pc-function d-none d-lg-block">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
            <span class="opt el-icon-arrow-left" @click="handleLeft"></span>
            <span class="opt el-icon-arrow-right" @click="handleRight"></span>
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="生产调度" name="0">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">生产调度</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备状态" name="1">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">设备状态</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="工艺参数" name="2">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">工艺参数</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="产品质量" name="3">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">产品质量</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="订单信息" name="4">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">订单信息</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="物料信息" name="5">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">物料信息</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="质量管理" name="6">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">质量管理</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备维护" name="7">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">设备维护</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备保养" name="8">
                <div class="wrap">
                  <div style="flex: 1;padding: 10px;">
                    <span class="sp">设备保养</span>
                    <p class="w-cot">
                      通过MES的工单管理功能，企业可以实现生产过程的数字化和智能化管理，提高生产效率和质量，降低成本，优化资源配置，提高客户满意度。同时，通过工单管理功能与其他系统的集成，可以实现数据的共享和流程的优化，帮助企业实现数字化和智能化制造的目标。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1;padding: 10px;">
                    <img
                      src="@/assets/newSite/product/s.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

  

      <div class="scene">
        <h4 class="pro-tit">适用场景</h4>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/scene1.png" alt="" />
                <h6>使用场景一</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/scene2.png" alt="" />
                <h6>使用场景一</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/scene3.png" alt="" />
                <h6>使用场景一</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/scene4.png" alt="" />
                <h6>使用场景一</h6>
              </div>
            </div>
 
 
 
 
          </div>

          <div class="row"></div>
        </div>
      </div>
   




      <right-nav-new class="d-none d-lg-block"/>



    </div>

    <NewFooter />
  </div>
</template>
  
  <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
  name: "hardware",
  data() {
    return {
      activeName: "1",

      tabType: 0,
      tab: 0,
      tabUl: 0,
      swiperOptionsCourse: {
        // height: 100,
      },
      swiperOptionsCoursePanel: {
        // height: 100,
      },
      active: "#home",
      scrollIntoViewOptions: {
        block: "start",
        behavior: "smooth",
      },
      distance_team: 0,
      distance_contact: 0,
      distance_join: 0,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    mySwiper() {
      return this.$refs.swiperCourse.$swiper;
    },
    coursePanel() {
      return this.$refs.coursePanel.$swiper;
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleLeft(){
        const arrs = ['0','1','2','3','4','5','6','7','8']
        const current = this.activeName
        let posIdx =  arrs.findIndex((n) => n == current)
        if(posIdx  == 0 ) return
        this.activeName =  arrs[posIdx-1]
    },
    handleRight(){
        const arrs = ['0','1','2','3','4','5','6','7','8']
        const current = this.activeName
        let posIdx =  arrs.findIndex((n) => n == current)
        if(current  == arrs.length -1 ) return
        this.activeName =  arrs[posIdx+1]
    },
    switchTab(index) {
      this.tabType = index;
      let e = $(".panel-nav").find("div");
      e = e[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.coursePanel.slideTo(index, 1000, false);
    },
    switchType(index) {
      let e = $(".banner-nav").find("li")[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.mySwiper.slideTo(index, 1000, false);
    },
    windowHeight() {
      var de = document.documentElement;
      console.log(de);
      return (
        self.innerHeight ||
        (de && de.offsetHeight) ||
        document.body.offsetHeight
      );
    },
    toTarget(target) {
      this.active = target;
      const toElement = document.querySelector(target);
      toElement.scrollIntoView(this.scrollIntoViewOptions);
    },
    onScroll() {
      let scrolled = Math.abs(
        document.getElementById("appp").getBoundingClientRect().top
      );
      if (scrolled < this.distance_team) {
        this.active = "#home";
      } else if (
        scrolled >= this.distance_team &&
        scrolled < this.distance_contact
      ) {
        this.active = "#team";
      } else if (
        scrolled >= this.distance_contact &&
        scrolled < this.distance_join
      ) {
        this.active = "#join";
      }
    },
    tabChange(e) {
      let tabid = e.target.id;
      if (tabid === this.tabUl || !tabid) return;
      var ul = document.querySelector(".ullist");
      console.log(tabid);
      ul.children[tabid].classList.remove("list2");
      ul.children[tabid].classList.add("list1");
      ul.children[this.tabUl].classList.remove("list1");
      ul.children[this.tabUl].classList.add("list2");
      this.tabUl = tabid;
      this.tab = tabid;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
  
  <style lang="less"  >
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/pro.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
      .exp {
        margin-top: 1rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/pro.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .customeValue {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        // border: 1px solid red;
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .sys {
    margin-bottom: 30px;
  }

  .pc-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }
      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          .el-tabs__nav-scroll .el-tabs__nav {
            width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            height: 400px;
            display: flex;
            padding: 30px;
            text-align: left;
            .sp{
                font-size: 16px;
                color:#333333;
                // font-weight: 700;
            }
            .w-cot{
                font-size: 14px;
                color:#666666;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            
          }
        }
      }
    }
  }

  .scene {
    margin: 50px 0;
    .pro-tit { 
      margin-bottom: 30px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      height: 200px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

























}

.content-title {
  width: 100%;
  background-color: #f3f8ff;
  height: 60px;
}
.content-title ul {
  max-width: 1240px;
  margin: 0 auto;
}
.content-title ul li {
  float: left;
  width: 64px;
  height: 60px;
  opacity: 1;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: right;
  color: #657687;
  line-height: 24px;
  margin-right: 126px;
}
//   #appp {
//     /* 关键代码，需要给容器添加高度 */
//     position: relative;
//     width: 100%;
//   }
.content-title ul li a {
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #808d9f;
  text-decoration: none;
}
.content-title ul li a:hover,
.content-title ul li a:active {
  color: #1584ff;
}
.content-title ul li a.active {
  color: #1584ff;
  border-bottom: 1px solid blue;
}
.Pain_pointbox {
  position: relative;
}
.Pain_pointbox_img {
  width: 100%;
  height: 100%;
}
#home,
#join,
#team {
  //   max-width: 1282px;
  width: 100%;
  margin: 0 auto;
  //   height: 200px;
  //   color: #ffff;
  font-size: 30px;
  text-align: center;
  //   border: 1px solid black;
  //   line-height: 200px;
  padding-top: 60px;
}
.Industry_background_box {
  position: relative;
  height: 650px;
  padding-left: 17%;
}
.Industry_background_top {
  height: 126px;
  max-width: 1281px;
  opacity: 1;
}
.Industry_background_top_p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #2c323b;
  line-height: 51px;
  margin: 16px 0px 50px 0px;
}
.Industry_background_top_p2 {
  height: 61px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #7d7d7d;
  line-height: 24px;
}
.problem {
  max-width: 1281px;
  margin-top: 120px;
}
#join {
  background: #f3f8ff;
  //   padding-bottom: 70px;
  height: 717px;
}
#join .title {
  height: 14px;
  opacity: 1;
  font-size: 1vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: center;
  color: #657687;
  padding-top: 5px;
}
#join .title2 {
  font-size: 4vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  // text-align: justify;
  color: #262c33;
}

.clickbtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 37%;
  top: 55%;
  border-radius: 30px;
  width: 9vw;
  height: 6vh;
  font-size: 2vh;
  border: 1px solid #1584ff;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.problem_box {
  display: flex;
  max-width: 1281px;
  // flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}
.problem_box1 {
  width: 25%;
  height: 298px;
  opacity: 1;
  background: #fbfcfd;
  border-radius: 10px;
  margin: 5px;
  position: relative;
}
.problem_box1_img {
  margin: 30px 0px 20px 0px;
}
.problem_box1_title {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #3a3a3a;
  line-height: 36px;
  margin-bottom: 20px;
}
.problem_box1_content {
  height: 81px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #3a3a3a;
  line-height: 27px;
  margin: 22px;
}
.problem_box1:hover {
  background-color: white;
  box-shadow: 0px 4px 18px 0px #daebff;
  border-bottom: 15px solid #1584ff;
}
#team {
  position: relative;
}
.Mechanics_5_img {
  position: relative;
}
.introduce {
  position: absolute;
  top: 19%;
  left: 39%;
}
.introduce .p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 51px;
}
.introduce .p2 {
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-top: 27px;
}
.introduce2 {
  position: absolute;
  top: 35%;
  left: 20%;
}
.introduce2_box {
  display: flex;
}
.introduce2_box_content {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.introduce2_box_content1 {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: 20px;
}
.introduce2_box_content_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content1_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.introduce2_box_content1_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content1_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content1_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.function {
  height: 51px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #262c33;
  line-height: 51px;
  margin-top: 60px;
}
.function_content {
  display: flex;
  margin: 0% 15%;
  //   border: 1px solid #1584ff;
}
.ulli {
  padding-left: 8%;
  height: 50%;
  padding-top: 7%;
}
.function_content_box {
  display: flex;
  width: 100%;
  margin-left: 10%;
}
.function_content_box_content {
  text-align: left;
  padding-top: 8%;
  width: 20vw;
}
.function_content_box_content .p1 {
  height: 21px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #657687;
  line-height: 21px;
}
.function_content_box_content .p2 {
  height: 36px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #262c33;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.function_content_box_content .p3,
.function_content_box_content .p4,
.function_content_box_content .p5,
.function_content_box_content .p6 {
  //   height: 33px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 26px;
  margin-top: 20px;
}
 
</style>
  